<!-- 帮助中心 -->
<template>
  <div class="HelpInfo">
    <LineTab :list="tabList" :active="activeKey" @tabChange="tabChange" />
    <CommonIssue v-show="activeKey === '1'" />
    <AboutQualification v-show="activeKey === '3'" />
    <AboutApproval v-show="activeKey === '2'" />
  </div>
</template>

<script>
import LineTab from "@/components/LineTab.vue";
import CommonIssue from "./component/CommonIssue";
import AboutApproval from "./component/AboutApproval.vue";
import AboutQualification from "./component/AboutQualification.vue";
export default {
  name: "HelpInfo",
  data() {
    return {
      activeKey: "1",
      tabList: [
        { name: "常见问题", code: "1", isShow: true },
        { name: "关于资质", code: "2", isShow: true },
        { name: "关于审批", code: "3", isShow: true }
      ]
    };
  },
  methods: {
    tabChange(code) {
      this.activeKey = code;
    }
  },
  components: { LineTab, CommonIssue, AboutApproval, AboutQualification }
};
</script>
<style lang="scss">
.HelpInfo {
  .LineTab {
    background: transparent;
    box-shadow: none;
    .item {
      font-size: 0.14rem;
      line-height: 0.2rem;
    }
    .active {
      font-size: 0.18rem;
      line-height: 0.25rem;
      &::after {
        bottom: -0.06rem;
      }
    }
  }
  .bgCard {
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0.16rem;
    margin: 0.1rem 0.18rem;
    .title {
      font-size: 0.15rem;
      font-weight: bold;
      line-height: 0.21rem;
    }
    .info {
      font-size: 0.13rem;
      color: #6e727a;
      line-height: 0.2rem;
    }
  }
}
</style>
