<!-- tab -->
<template>
  <div class="LineTab">
    <template v-for="item in list">
      <div
        :class="['item', { active: activeTab == item.code }]"
        :key="item.code"
        @click="tabChange(item.code)"
        v-show="item.isShow"
      >
        {{ item.name }}
      </div>
    </template>
    <!-- 右侧自定义区域 -->
    <div class="tabIcon"><slot /></div>
  </div>
</template>

<script>
export default {
  name: "LineTab",
  props: {
    list: { type: Array },
    active: { type: String }
  },
  computed: {
    activeTab: {
      get() {
        return this.active;
      },
      set() {}
    }
  },
  methods: {
    tabChange(code) {
      this.activeTab = code;
      this.$emit("tabChange", code);
    }
  }
};
</script>
<style lang="scss" scoped>
.LineTab {
  height: 0.52rem;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 0.18rem 0 0.03rem;
  box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 0, 0, 0.05);
  .item {
    padding: 0 0.15rem;
    margin-right: 0.05rem;
    font-size: 0.16rem;
    color: #3a3d46;
    letter-spacing: 0;
    line-height: 0.18rem;
  }
  .active {
    font-size: 0.16rem;
    font-weight: bold;
    color: $mainRed;
    line-height: 0.2rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 35%;
      height: 0.04rem;
      border-radius: 0.03rem;
      background: $mainRed;
      bottom: -0.15rem;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .tabIcon {
    flex: 1;
    text-align: right;
  }
}
</style>
